import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/killjack.png";
import ingamegameplay from "../assets/img/ingamedialogue.png";
import ingametv from "../assets/img/jackscasinotv.png";
import ingamedeath from "../assets/img/Death.png";
import restartmenu from "../assets/img/Restart menu.png"
import colorSharp2 from "../assets/img/color-sharp2.png";
import NightRunner from "../assets/img/nr.png";
import YouWeapon from "../assets/img/ytw.png";
import AOT from "../assets/img/aotscreen.png";
import pokemon from "../assets/img/pcode.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Kill Jack",
      description: "Ein in Godot entwickeltes Blackjack-Spiel, bei dem das Ziel ist, Jack, den Dealer, zu besiegen, um aus seinem Keller zu entkommen.",
      imgUrl: projImg1,
    },
    {
      title: "Design",
      description: "Eigene selbsterstellte Zwischensequenzen",
      imgUrl: ingametv,
    },
    {
      title: "Storytelling",
      description: "Interressantes Storytelling um das Spiel spannender zu gestalten.",
      imgUrl: ingamegameplay,
    },
    {
      title: "KI",
      description: "Eigene Künstliche Intelligenz für den Dealer",
      imgUrl: ingamedeath,
    },
    {
      title: "UI",
      description: "Simple und verständliche Menüs",
      imgUrl: restartmenu,
    }
  ];

  const otherGames = [
    {
      title: "Night Runner",
      description: "Ein in Unity entwickelter 2D Runner, bei dem das Ziel ist, die Level abzuschließen bevor man von der dunklen Materie ausgelöscht wird.",
      imgUrl: NightRunner,
    },
    {
      title: "You are the Weapon",
      description: "Ein endlos 2D Combat Spiel bei dem man die Schleimgegner aus den Röhren besiegen muss um Punkte zu sammeln.",
      imgUrl: YouWeapon,
    },
  ]
  const discordBots = [
    {
      title: "Anilist Bot (API)",
      description: "Ein Chatbot um verschiedene Anime-Empfehlungen aufgrund seiner Watchhistory zu bekommen / API abfragen",
      imgUrl: AOT,
    },
    {
      title: "Pokemon Card Bot (API)",
      description: "Ein Chatbot um die Preise seiner eigenen Pokemon Karten herauszufinden.",
      imgUrl: pokemon,
    },
  ]

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Meine Projekte</h2>
                <p>Hier sind meine letzten Projekte, auf die ich noch zugriff habe. Den Rest habe ich leider verloren, da mir als kleines Kind nicht bewusst war, dass sie mal interessant sein könnten.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Kill Jack</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Andere Spiele</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Discord Bots</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <p>Kill Jack ist mein bisher größtes Projekt und auch das auf welches ich am meisten Stolz bin. Es ist noch nicht ganz fertig aber wird mit der Zeit immer besser.</p>
                      <p>(Hover über die Bilder für mehr Infos)</p>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          otherGames.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          discordBots.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
